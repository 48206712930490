import React, { StrictMode } from 'react';

import Routes from './Routes';
import GlobalStyles from './shared/utils/theme/GlobalStyles';

// This code is for temporarily suppressing the console errors about defaultProps being removed from function components in a future release
// eslint-disable-next-line prefer-destructuring
// const error = console.error;
// console.error = (...args) => {
//   if (/defaultProps/.test(args[0])) return;
//   error(...args);
// };

// Remove generated theme-color meta tag to dynamically set it based on light/dark mode
const themeColorMeta = document.querySelector("[name='theme-color']");
if (themeColorMeta) {
  themeColorMeta.remove();
}

function App() {
  return (
    <StrictMode>
      <>
        <GlobalStyles />
        <Routes />
      </>
    </StrictMode>
  );
}

export default App;
