import { alpha } from '@mui/material/styles';

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};

// Main MUI palette colors
// const PRIMARY = {
//   lighter: '#ffd170',
//   light: '#ffbd33',
//   main: '#ffb61f',
//   dark: '#f2a300',
//   darker: '#bf7200',
//   contrastText: GREY[0]
// };
const RAY_LOGO_BLUE = {
  lighter: '#8797c3',
  light: '#5571ab',
  main: '#0f4d92',
  dark: '#173c71',
  darker: '#172c50',
  contrastText: GREY[0]
};

const SECONDARY = {
  lighter: '#DEF0FF',
  light: '#83B4FF',
  main: '#5A9BFF',
  dark: '#0467FF',
  darker: '#11297A',
  contrastText: GREY[0]
};
const INFO = {
  lighter: '#D5FEFF',
  light: '#64D3FF',
  main: '#30C4FF',
  dark: '#00A2E3',
  darker: '#093B7A',
  contrastText: GREY[800]
};
const SUCCESS = {
  lighter: '#CDFDD5',
  light: '#43F4A7',
  main: '#0DE889',
  dark: '#0AAE67',
  darker: '#026F6B',
  contrastText: GREY[800]
};
const WARNING = {
  lighter: '#FFFBD9',
  light: '#FFEA73',
  main: '#FFE344',
  dark: '#F2CE00',
  darker: '#7A630D',
  contrastText: GREY[800]
};
const ERROR = {
  lighter: '#FFEEDC',
  light: '#FF9D7F',
  main: '#FF7C54',
  dark: '#FE3B00',
  darker: '#7A1015',
  contrastText: GREY[0]
};

// Misc colors
const DARK_BLUE = {
  light: '#5782d2',
  main: '#4a77c7',
  dark: '#3064b2',
  contrastText: GREY[0]
};
const PURPLE = {
  light: '#BA68C8',
  main: '#9C27B0',
  dark: '#7B1FA2',
  contrastText: GREY[0]
};
const LIGHT_PINK = {
  light: '#fc91ac',
  main: '#fa5a83',
  dark: '#f8235a',
  contrastText: GREY[0]
};
const PINK = {
  light: '#fa5a83',
  main: '#e74a74',
  dark: '#d43764',
  contrastText: GREY[0]
};
const DARK_PINK = {
  light: '#cb4573',
  main: '#bf3a6a',
  dark: '#ab2359',
  contrastText: GREY[0]
};

const lightPalette = {
  mode: 'light',
  common: {
    black: '#302d2d',
    white: '#fcfcff'
  },
  primary: { ...RAY_LOGO_BLUE },
  secondary: { ...SECONDARY },
  info: { ...INFO },
  success: { ...SUCCESS },
  warning: { ...WARNING },
  error: { ...ERROR },
  darkBlue: { ...DARK_BLUE },
  purple: { ...PURPLE },
  pink: { ...PINK },
  lightPink: { ...LIGHT_PINK },
  darkPink: { ...DARK_PINK },
  grey: GREY,
  divider: GREY[500_24],
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500]
  },
  background: {
    paper: GREY[0],
    default: GREY[100],
    neutral: GREY[200]
  },
  action: {
    active: GREY[600],
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_12],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

export default lightPalette;
