const typography = {
  fontFamily: 'proxima-nova, sans-serif',
  fontWeightMedium: 600,
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5
  },
  body2: {
    lineHeight: 22 / 14
  },
  button: {
    textTransform: 'capitalize'
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: '0.75rem',
    letterSpacing: 1.1,
    textTransform: 'uppercase'
  }
};

export default typography;
