import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';

import ThemeConfig from './shared/utils/theme';
import App from './App';

// Google Analytics
// TODO: Add proper tracking IDs when Analytics is setup for RAY site!
// ReactGA.initialize([
//   {
//     trackingId: 'G-Z23GZFLYT0'
//   },
//   {
//     trackingId: 'AW-10904940310'
//   },
//   {
//     trackingId: 'GTM-PR25QXS7'
//   }
// ]);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <ThemeConfig>
    <SnackbarProvider
      maxSnack={10}
      dense
      anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      autoHideDuration={5000}
      preventDuplicate
    >
      <Router>
        <App />
      </Router>
    </SnackbarProvider>
  </ThemeConfig>
);
