import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import LoadingContainer from './shared/containers/Loading';

const AsyncHome = lazy(() => import('./routes/Home'));
// const AsyncContact = lazy(() => import('./routes/Home/Contact'));
const AsyncPrivacy = lazy(() => import('./routes/Home/Privacy'));
const AsyncTerms = lazy(() => import('./routes/Home/Terms'));
const AsyncJoinWaitlist = lazy(() => import('./routes/Actions/JoinWaitlist'));

const Routes = () => {
  return (
    <Suspense fallback={<LoadingContainer />}>
      <Switch>
        <Route path="/join-waitlist">
          <AsyncJoinWaitlist />
        </Route>
        {/* <Route path="/contact-us">
          <AsyncContact />
        </Route> */}
        <Route path="/terms">
          <AsyncTerms />
        </Route>
        <Route path="/privacy-policy">
          <AsyncPrivacy />
        </Route>
        <Route exact path="/">
          <AsyncHome />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default Routes;
